import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';


class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why is Binance Clone Software </span>
              Beneficial to Entrepreneurs?
              </h2>
              <p className="pharagraph head">As a proprietor of the Binance-like crypto exchange trading platform we offer, you can yield the following benefits by using our first-rate software.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance/why-binance-clone-benificial-to-entrepreneurs.png"
                  alt="Why is Binance Clone Software Beneficial to Entrepreneurs Image1"
                  width={449}
                />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >High scalability
                </li>
                <li className="pharagraph" >Cost-effective
                </li>
                <li className="pharagraph" >Faster deployment
                </li>
                <li className="pharagraph" >Users can get a scintillating trading experience
                </li>
                <li className="pharagraph" >High Customization Scope
                </li>
                <li className="pharagraph" >Huge ROI</li>
                <li className="pharagraph" >No need for technical expertise
                </li>
                <li className="pharagraph" >Comes with Live Order Book System
                </li>
                <li className="pharagraph" >Highly secure and Trustworthy
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould