import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'

class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
            Binance Clone Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Liquidity API Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Stop-limit and OCO order
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  IEO launchpad
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  NFT Marketplace
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Crypto Loans
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs6')} >
                  Crypto Staking
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Liquidity API Integration
                      </h4>
                      <p className="pharagraph">Our solution to launch a Binance-like exchange platform can be connected with an order book through a secure API connection which helps in achieving high liquidity. As an admin, you will be completing the user orders under your account and the order status gets updated in the user's account after order completion.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/binance-liquidity-api-integration.png"
                      alt="Liquidity API Integration"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Stop-limit and OCO order
                      </h4>
                      <p className="pharagraph">A stop limit order is a limit order restricted with a stop or trigger price. Only when the stop price is reached the order falls in the order book as a limit order. OCO (One Cancels the Other) order allows users to place two orders at the same time. But only one of the two can be executed and if any one order is partially or fully filled then the other order gets cancelled automatically.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/Stop-limit-order.png"
                      alt="Stop-limit and OCO order"
                      width={450}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">IEO launchpad
                      </h4>
                      <p className="pharagraph">We enable an IEO launchpad which works similarly to the launchpad available in popular Crypto exchanges. The IEO launchpad will open a portal for your users to sell their newly launched cryptocurrencies and raise capital for their businesses.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/binance-ieo-launchpad.png"
                      alt="IEO launchpad"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">NFT Marketplace</h4>
                      <p className="pharagraph">It is one of the newest and trending modules where users can create and trade top NFTs in the market. A Non-Fungible Token (NFT) is a digital token that represents a real entity which can be a drawing, animated GIFs, songs, or items in video games, etc.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/binance-nft-marketplace.png"
                      alt="NFT Marketplace"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Crypto Loans
                      </h4>
                      <p className="pharagraph">Users can get crypto loans from the admin by using any other available coins as collateral. Interest will be calculated and the user needs to pay the interest and principal within their selected time period.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/binance-crypto-loans.png"
                      alt="Crypto Loans"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs6" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Crypto Staking </h4>
                      <p className="pharagraph">Users can earn interest over time for holding certain cryptocurrencies. Long-time crypto holders can make use of staking and earn rewards. Also by staking users are strengthening the transaction power of the blockchain. 
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/binance-crypto-staking.png"
                      alt="Crypto Staking"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon