import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our Binance Clone Script</h3>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">High-Performance Matching Engine</h4>
                <p className="pharagraph">We enabled a high-performance matching engine in our premium feature-backed Binance clone app so that your users can perform faster and more responsive trades.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/high-performance-matching-engine.png"
                  alt="High-Performance Matching Engine"
                  width={555}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/spot-trading.png"
                  alt="Spot Trading"
                  width={555}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Spot Trading</h4>
                <p className="pharagraph">Spot trading is one of the premium features of our Binance clone development. By using this feature, instant cryptocurrency trading can be done securely between the buyer and seller by posting buy/sell orders which will be matched by the ultra-fast matching engine.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Margin Trading</h4>
                <p className="pharagraph">Margin trading allows your users to borrow funds from the admin to trade the crypto assets in a risk-free manner. Therefore, your users can increase their profit by leveraging the investment.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/margin-trading.png"
                  alt="Margin Trading"
                  width={555}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/futures-trading.png"
                  alt="Futures Trading"
                  width={555}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Futures Trading</h4>
                <p className="pharagraph">The advanced and key feature in our Binance clone app development helps your users to buy/sell assets at a predetermined price on a specific date or without any expiry date. Our software comes with perpetual (no expiry date) and quarterly contracts (expires every quarter) where users can hold their position accordingly by speculating the cryptocurrency's future price.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">P2P Trading</h4>
                <p className="pharagraph">Most cryptocurrency traders prefer p2p, so we enabled the p2p feature in our software to provide the best trade experience. Your users can perform secure and instant cryptocurrency trading by selecting the preferred buy/sell advertisements or by posting buy/sell ads based on their preference.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/p2p-trading.png"
                  alt="P2P Trading"
                  width={555}
                />
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/otc-trading.png"
                  alt="OTC Trading"
                  width={555}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">OTC Trading</h4>
                <p className="pharagraph mb-0">This feature helps your users to trade cryptocurrencies right away by eliminating the need to visit the order book. It is an instant buy/sell feature where users can trade cryptos with their convenient payment methods.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures