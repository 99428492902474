import React from 'react'




class Whatisbinance extends React.Component {



  render() {


    return (
      <section className="whatis py-0 mb-0">
        <div className='gray-bg transp'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
                 Binance Clone Script?</h2>
              </div>
            </div>

            <div className="text-center">
              <p className="pharagraph mb-0">Binance clone script is a ready-made software solution that helps to start a crypto exchange with the core features & functionalities of Binance. Our power-packed solution to develop a Binance-like exchange is fully designed, developed, verified by experts, tested, and ready for deployment. We develop a premium crypto exchange like Binance using cutting-edge technologies and state-of-the-art infrastructure. With our pre-made industry-leading Binance clone software, you can gain a massive count of users to your cryptocurrency exchange platform and reap a huge amount of revenue.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatisbinance