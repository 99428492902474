import React, { useState, useEffect } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const [whatsappLink, setWhatsappLink] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
      const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
      setWhatsappLink(link);
    }
  }, []);

  return (
    <section className="faq" id="faqs">

      <Container>
        <div className="text-center">

          <h4 className="heading-h2"><span className="heading-h3 bluecolor d-none d-sm-block">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                How can I benefit as a business owner by launching a crypto exchange like Binance?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">By launching a Crypto exchange like Binance, business owners can enter the market quickly and create a brand easily. Also, your users can get an extraordinary trading experience by launching an exchange like Binance. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How can I generate revenue via the Binance clone script?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Trading fees, Listing fees, Withdrawal fees, IEO Launchpad, and Margin Trading fees are some of the ways to generate revenue through the Binance clone script. Additionally, you can add or customize the revenue-generating streams in the software. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How much time will it take to launch an exchange platform using the Binance clone script?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The average estimated time will be 7 to 10 days to launch an ideal crypto exchange platform like Binance using our Binance clone script. However, If you need any further customizations along with additional security and trading features, then it might take extra time depending on the customizations(design, themes, etc) and features you prefer.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How much does it cost to develop a Crypto exchange like Binance?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The cost of a Binance clone script ranges from $7,000 to $15,000 usually. However, the cost can vary depending on the number of features needed and the extra customizations. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                Why should you choose us for Binance Clone?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">We, a highly reputed crypto exchange development company have a portfolio of 100+ successful blockchain projects. We specialize in providing clone scripts for entrepreneurs and have a team of expert developers to build an efficient crypto exchange for you.
                <p className="faq-get">Get a Free Demo</p>
                <p>Launch a crypto exchange like binance with Coinsclone! Talk to our team experts and get a free demo of our ultra-modern binance clone script right away!</p>
                <p className="email">Email</p>
                <p className="pharagraph sbold"><a href="mailto:hello@coinsclone.com">hello@coinsclone.com</a></p>
                <p className="whats">Whatsapp</p>
                  <p className="pharagraph sbold">
                    <a href={whatsappLink} rel="noreferrer" target="_blank">+91 9500575285</a>
                  </p>
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection 