import React from 'react'


const Dex = () => {

  return (
    <section className="security dex pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            
            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Binance DEX</span></span> Clone Script and Features
            </h2>
            <p className="pharagraph head">Binance DEX Script is a pre-built, customizable software that contains all the existing features and functionalities of the Binance DEX platform. Our ready-made crypto exchange app like Binance eliminates the need for an admin to access the trade. It comes with all essential features and plug-ins of the popular DEX platforms. We offer you a highly responsive DEX script with extraordinary features as per the business necessities through which you can earn better trading fees.
            </p>
          </div>
        </div>
        <div className="binanceflexbox mt-0">
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5552" data-name="Rectangle 5552" width="34" height="34" transform="translate(-0.139 -0.139)" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Completely_decentralized_order_book" data-name="Completely decentralized  order book" transform="translate(0.139 0.139)">
                    <g id="Group_25100" data-name="Group 25100" transform="translate(0 0)" clip-path="url(#clip-path)">
                      <path id="Path_17479" data-name="Path 17479" d="M210.274,0a4.487,4.487,0,0,1,1.592.759,3.155,3.155,0,0,1-.765,5.29.432.432,0,0,0-.322.485c.021.551.006,1.1.006,1.665h-1.919c0-.493-.028-1,.009-1.5a.608.608,0,0,0-.439-.7,3.111,3.111,0,0,1-1.757-3.107A3.163,3.163,0,0,1,208.84.176c.18-.057.359-.117.539-.176Z" transform="translate(-192.965 0.531)" fill="gray" />
                      <path id="Path_17480" data-name="Path 17480" d="M0,209.428a4.486,4.486,0,0,1,.759-1.592,3.155,3.155,0,0,1,5.29.764.432.432,0,0,0,.485.322c.551-.021,1.1-.006,1.665-.006v1.919c-.493,0-1,.028-1.5-.009a.608.608,0,0,0-.7.439,3.111,3.111,0,0,1-3.107,1.757,3.163,3.163,0,0,1-2.709-2.16c-.057-.18-.117-.359-.176-.539v-.9" transform="translate(0.486 -192.97)" fill="gray" />
                      <path id="Path_17481" data-name="Path 17481" d="M392.025,210.274a4.487,4.487,0,0,1-.759,1.592,3.155,3.155,0,0,1-5.289-.765.432.432,0,0,0-.485-.322c-.551.021-1.1.006-1.665.006v-1.919c.493,0,1-.028,1.5.009a.608.608,0,0,0,.7-.439,3.111,3.111,0,0,1,3.107-1.757,3.163,3.163,0,0,1,2.709,2.16c.057.18.117.359.176.539Z" transform="translate(-358.788 -192.92)" fill="gray" />
                      <path id="Path_17482" data-name="Path 17482" d="M209.428,392.025a4.487,4.487,0,0,1-1.592-.759,3.155,3.155,0,0,1,.765-5.289.432.432,0,0,0,.322-.485c-.021-.551-.006-1.1-.006-1.665h1.919c0,.493.028,1-.009,1.5a.608.608,0,0,0,.439.7,3.111,3.111,0,0,1,1.757,3.107,3.163,3.163,0,0,1-2.16,2.709c-.18.057-.359.117-.539.176Z" transform="translate(-193.014 -358.744)" fill="gray" />
                      <path id="Path_17483" data-name="Path 17483" d="M170.418,164.15a6.277,6.277,0,1,1-6.278-6.284,6.279,6.279,0,0,1,6.278,6.284" transform="translate(-147.279 -147.237)" fill="gray" />
                      <path id="Path_17484" data-name="Path 17484" d="M68.047,338.08c-.337.331-.741.74-1.161,1.132a.345.345,0,0,0-.1.462,3.154,3.154,0,0,1-1.219,3.865,3.162,3.162,0,0,1-4.226-4.565,3.152,3.152,0,0,1,3.716-1.022.368.368,0,0,0,.487-.106c.394-.416.8-.817,1.139-1.154l1.359,1.389" transform="translate(-56.355 -314.624)" fill="gray" />
                      <path id="Path_17485" data-name="Path 17485" d="M336.461,338.2l1.38-1.358c.309.309.709.685,1.081,1.087a.469.469,0,0,0,.62.138,3.155,3.155,0,1,1-1.846,4.036,2.99,2.99,0,0,1,.049-2.3.327.327,0,0,0-.088-.436c-.427-.4-.838-.814-1.2-1.165" transform="translate(-314.453 -314.768)" fill="gray" />
                      <path id="Path_17486" data-name="Path 17486" d="M68.062,66.535,66.691,67.9c-.325-.329-.735-.726-1.123-1.143a.383.383,0,0,0-.513-.106,3.16,3.16,0,0,1-4.12-1.8,3.123,3.123,0,0,1,2.056-4.141,2.984,2.984,0,0,1,3.183.853,3.021,3.021,0,0,1,.618,3.375.335.335,0,0,0,.093.435c.424.4.833.819,1.178,1.162" transform="translate(-56.363 -56.159)" fill="gray" />
                      <path id="Path_17487" data-name="Path 17487" d="M336.6,66.708c.348-.34.753-.747,1.171-1.139a.342.342,0,0,0,.1-.459,3.161,3.161,0,1,1,1.733,1.723.365.365,0,0,0-.486.1c-.393.417-.8.817-1.141,1.156L336.6,66.708" transform="translate(-314.583 -56.342)" fill="gray" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Completely Decentralized
                  Order Book</p> </div>
            </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="Blockchain_security_with_user_s_control_of_their_private_keys" data-name="Blockchain security with user’s  control of their private keys" xmlns="http://www.w3.org/2000/svg" width="32.749" height="32.75" viewBox="0 0 32.749 32.75">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5553" data-name="Rectangle 5553" width="32.749" height="32.75" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_25101" data-name="Group 25101" clip-path="url(#clip-path)">
                    <path id="Path_17488" data-name="Path 17488" d="M366.819,398.784a3.228,3.228,0,0,1-1.583-.763,2.848,2.848,0,0,1-.865-2.875.493.493,0,0,0-.1-.515q-.618-.691-1.194-1.418a.629.629,0,0,0-.57-.254c-.722.014-1.445.005-2.188.005v-1.91c1.042,0,2.074-.026,3.1.019a1.168,1.168,0,0,1,.683.411c.552.6,1.065,1.244,1.6,1.878a2.765,2.765,0,0,1,2.326-.243,2.818,2.818,0,0,1,1.93,2.189c.016.072.038.142.058.213v.64a.959.959,0,0,0-.05.149,2.976,2.976,0,0,1-1.005,1.783,3.413,3.413,0,0,1-1.5.69Z" transform="translate(-337.268 -366.034)" fill="gray" />
                    <path id="Path_17489" data-name="Path 17489" d="M370.041,184.277a3.491,3.491,0,0,1-.681,1.525,2.823,2.823,0,0,1-3.058.875.452.452,0,0,0-.553.119c-.525.53-1.08,1.03-1.61,1.554a1.311,1.311,0,0,1-1,.408c-.925-.015-1.851,0-2.8,0v-1.919c.728,0,1.449-.015,2.17.007a.918.918,0,0,0,.754-.308c.361-.377.744-.733,1.131-1.083a.342.342,0,0,0,.1-.456,2.712,2.712,0,0,1,.594-3.015,2.684,2.684,0,0,1,2.8-.785,2.774,2.774,0,0,1,2.088,2.261c.013.062.036.121.054.182Z" transform="translate(-337.293 -169.498)" fill="gray" />
                    <path id="Path_17490" data-name="Path 17490" d="M369.987,304.325a3.54,3.54,0,0,1-.68,1.524,2.852,2.852,0,0,1-4.749-.572.477.477,0,0,0-.525-.318c-1.129.015-2.258.006-3.388.006h-.361v-1.919h.329c1.183,0,2.365,0,3.547,0a.31.31,0,0,0,.34-.2,2.857,2.857,0,0,1,5.44.719.674.674,0,0,0,.046.118Z" transform="translate(-337.238 -281.871)" fill="gray" />
                    <path id="Path_17491" data-name="Path 17491" d="M10.55,211.037q3.773,0,7.545,0a2.9,2.9,0,0,1,2.438,1.167,2.839,2.839,0,0,1,.574,1.759c0,3.8.013,7.609,0,11.413A2.849,2.849,0,0,1,18.19,228.3q-7.625.022-15.25,0a2.856,2.856,0,0,1-2.93-2.938q-.02-5.691,0-11.381A2.87,2.87,0,0,1,3,211.039c2.515-.007,5.03,0,7.545,0m.967,11.062c0-.437.015-.875-.006-1.31a.426.426,0,0,1,.3-.476,2.847,2.847,0,0,0,1.126-4.178A2.873,2.873,0,0,0,7.69,217.6a2.835,2.835,0,0,0,1.677,2.741.349.349,0,0,1,.236.383c-.01.906-.009,1.811,0,2.717a.96.96,0,1,0,1.915,0c.006-.447,0-.9,0-1.343" transform="translate(0 -197.537)" fill="gray" />
                    <path id="Path_17492" data-name="Path 17492" d="M44.178,11.559c.06-1.737,0-3.463.207-5.157a6.977,6.977,0,0,1,3.344-5.134A7.581,7.581,0,0,1,57.71,2.679a7.425,7.425,0,0,1,1.869,4.942c.018,1.257,0,2.515,0,3.773,0,.051-.006.1-.011.17H55.747c0-.105,0-.217,0-.329,0-1.2.015-2.409,0-3.613a3.831,3.831,0,0,0-7.66-.081c-.029,1.214-.008,2.43-.009,3.645,0,.116,0,.232,0,.372Z" transform="translate(-41.352 0)" fill="gray" />
                    <path id="Path_17493" data-name="Path 17493" d="M151.071,301.078a.957.957,0,1,1-.983.931.967.967,0,0,1,.983-.931" transform="translate(-140.487 -281.819)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Cryptocurrency wallet integration
                </p>
              </div>
            </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="More_than_150_cryptocurrencies_are_supportive" data-name="More than 150 cryptocurrencies  are supportive" xmlns="http://www.w3.org/2000/svg" width="32.75" height="32.742" viewBox="0 0 32.75 32.742">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5554" data-name="Rectangle 5554" width="32.75" height="32.742" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_25102" data-name="Group 25102" clip-path="url(#clip-path)">
                    <path id="Path_17494" data-name="Path 17494" d="M0,376.616a9.07,9.07,0,0,1,.321-.856,2.651,2.651,0,0,1,1.353-1.3.359.359,0,0,0,.249-.4c-.011-.692-.006-1.384,0-2.076a.968.968,0,0,1,1.05-1.055q1.357,0,2.715,0a.416.416,0,0,1,.283.083c.509.6,1,1.2,1.519,1.823H3.838c0,.485-.012.94.012,1.392,0,.081.149.174.248.226a2.884,2.884,0,0,1-.139,5.273,5.9,5.9,0,0,1-.7.215H2.494c-.081-.024-.161-.049-.242-.07a2.87,2.87,0,0,1-2.115-1.936c-.056-.159-.092-.325-.137-.488v-.831" transform="translate(0 -347.198)" fill="gray" />
                    <path id="Path_17495" data-name="Path 17495" d="M398.439,379.9a6.625,6.625,0,0,1-1.223-.511,2.853,2.853,0,0,1,.395-4.967.359.359,0,0,0,.258-.4c-.018-.392-.005-.785-.005-1.222h-3.693c.077-.095.124-.156.174-.215.43-.5.869-.992,1.286-1.5a.479.479,0,0,1,.424-.2c.894.01,1.789,0,2.683.005a.964.964,0,0,1,1.043,1.031c0,.724-.005,1.448.009,2.172a.381.381,0,0,0,.164.284,2.876,2.876,0,0,1-.662,5.485.387.387,0,0,0-.085.043Z" transform="translate(-368.958 -347.153)" fill="gray" />
                    <path id="Path_17496" data-name="Path 17496" d="M0,2.591c.053-.183.1-.368.159-.548A2.872,2.872,0,0,1,5.693,2.4,2.846,2.846,0,0,1,4.129,5.553a.42.42,0,0,0-.3.471c.023.379.006.761.006,1.172H7.522A14.812,14.812,0,0,0,6.337,8.575a1.143,1.143,0,0,1-1.158.548c-.732-.045-1.469-.009-2.2-.012A.969.969,0,0,1,1.919,8.059c0-.714.005-1.427-.008-2.141a.379.379,0,0,0-.154-.289A2.915,2.915,0,0,1,.04,3.468c0-.018-.026-.031-.04-.047V2.591" transform="translate(0 -0.09)" fill="gray" />
                    <path id="Path_17497" data-name="Path 17497" d="M0,191.7c.032-.052.067-.1.1-.156a.956.956,0,0,1,1.82.42c.009.628,0,1.257,0,1.886,0,.1,0,.208,0,.336H3.585V196.1H1.918v.8c0,.447,0,.895,0,1.342a.981.981,0,0,1-.76,1.041.969.969,0,0,1-1.1-.614A.348.348,0,0,0,0,198.6V191.7" transform="translate(0 -178.778)" fill="gray" />
                    <path id="Path_17498" data-name="Path 17498" d="M191.626,459.431c-.042-.027-.083-.058-.127-.081a.958.958,0,0,1,.422-1.835c.618-.006,1.236,0,1.854,0h.336v-1.666h1.914v1.666h.8c.448,0,.9,0,1.343,0a.98.98,0,0,1,1.041.76.969.969,0,0,1-.614,1.1.339.339,0,0,0-.067.059Z" transform="translate(-178.708 -426.689)" fill="gray" />
                    <path id="Path_17499" data-name="Path 17499" d="M85.985,96.938a10.871,10.871,0,1,1,10.841,10.876A10.875,10.875,0,0,1,85.985,96.938m9.324-6.706c-.445,0-.839,0-1.233,0a.97.97,0,0,0-1.054,1.055q0,5.641,0,11.282a.979.979,0,0,0,1.086,1.088c.39,0,.781,0,1.188,0,.005.121.011.2.012.287,0,.213,0,.426,0,.639a.956.956,0,0,0,.919.991.968.968,0,0,0,.993-.956c.016-.314,0-.63,0-.967,2.129.092,3.749-.675,4.484-2.706a3.969,3.969,0,0,0-1.6-4.8,3.539,3.539,0,0,0,.448-3.878,3.585,3.585,0,0,0-3.335-2.055c0-.328.013-.636,0-.942a.968.968,0,0,0-.993-.957.953.953,0,0,0-.92.99c-.006.3,0,.592,0,.927" transform="translate(-80.485 -80.57)" fill="gray" />
                    <path id="Path_17500" data-name="Path 17500" d="M394.772,7.1h3.654c0-.477.012-.922-.011-1.366,0-.085-.135-.19-.231-.239a2.822,2.822,0,0,1-1.671-2.626,2.873,2.873,0,1,1,4.134,2.582.454.454,0,0,0-.309.5c.018.671.009,1.342.005,2.013a.969.969,0,0,1-1.053,1.055q-1.358,0-2.717,0a.413.413,0,0,1-.282-.086c-.508-.6-1-1.2-1.52-1.827" transform="translate(-369.521 0.001)" fill="gray" />
                    <path id="Path_17501" data-name="Path 17501" d="M196.059,3.678h-1.924V2c-.766,0-1.5.01-2.23,0a.951.951,0,0,1-.884-1.344.905.905,0,0,1,.856-.567q3.211-.01,6.423,0A.956.956,0,1,1,198.28,2c-.628.01-1.257,0-1.885,0h-.336Z" transform="translate(-178.719 -0.078)" fill="gray" />
                    <path id="Path_17502" data-name="Path 17502" d="M457.452,196.155h-1.67v-1.912h1.67v-.327c0-.639-.008-1.278,0-1.917a.956.956,0,1,1,1.912-.009q.012,3.195,0,6.389a.956.956,0,1,1-1.913-.007c-.009-.723,0-1.447,0-2.217" transform="translate(-426.627 -178.819)" fill="gray" />
                    <path id="Path_17503" data-name="Path 17503" d="M225.985,268.167V263.9h.872c.692,0,1.383-.008,2.074,0a2.136,2.136,0,0,1,2.161,2.038,2.114,2.114,0,0,1-2,2.221c-1.017.053-2.039.011-3.106.011" transform="translate(-211.53 -247.014)" fill="gray" />
                    <path id="Path_17504" data-name="Path 17504" d="M226.112,184.382v-3.359c.086-.009.167-.024.247-.024.7,0,1.4-.007,2.106,0a1.687,1.687,0,0,1,.25,3.359,2.465,2.465,0,0,1-.381.022c-.731,0-1.462,0-2.222,0" transform="translate(-211.649 -169.419)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Multi-cryptocurrency support
                </p>
              </div></div>
          </div>
        </div>
        <div className="binanceflexbox">
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="Cryptocurrency_wallet_integration" data-name="Cryptocurrency wallet  integration" xmlns="http://www.w3.org/2000/svg" width="32.75" height="25.568" viewBox="0 0 32.75 25.568">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5555" data-name="Rectangle 5555" width="32.75" height="25.568" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_25103" data-name="Group 25103" clip-path="url(#clip-path)">
                    <path id="Path_17505" data-name="Path 17505" d="M0,57.961l3.389.186V79.815c-.87,0-1.72.018-2.568-.008C.406,79.8.188,79.468,0,79.139Z" transform="translate(0 -54.254)" fill="gray" />
                    <path id="Path_17506" data-name="Path 17506" d="M0,.7A1.256,1.256,0,0,1,1.3,0Q14.621.018,27.942.008h.439V1.914H28q-13.369,0-26.738.008A1.21,1.21,0,0,1,0,1.212Z" transform="translate(0 0)" fill="gray" />
                    <path id="Path_17507" data-name="Path 17507" d="M109,66.09c-.149,0-.285,0-.42,0-1.193.017-2.394-.047-3.578.068a4.9,4.9,0,0,0-4.3,4.663,4.959,4.959,0,0,0,4.012,5.117,7.2,7.2,0,0,0,1.176.1c1.032.013,2.064,0,3.113,0v5.884H83.8V60.215H109ZM94.7,76.957V75.825a2.99,2.99,0,0,0,1.972-5.3,2.723,2.723,0,0,0,.163-2.67,2.8,2.8,0,0,0-2.16-1.587V65.132H92.753v1.146H90.1v1.916h1.05v5.7H90.1v1.924H92.77v1.136Z" transform="translate(-78.435 -56.363)" fill="gray" />
                    <path id="Path_17508" data-name="Path 17508" d="M386.577,181.911v6.05a2.065,2.065,0,0,1-.244.029c-1.717,0-3.433.012-5.15,0a3.059,3.059,0,0,1-3.117-2.455,3.017,3.017,0,0,1,2.8-3.638c1.875-.062,3.753-.016,5.629-.016a.341.341,0,0,1,.083.033m-4.764,2.083h-2.13v1.878h2.13Z" transform="translate(-353.827 -170.229)" fill="gray" />
                    <path id="Path_17509" data-name="Path 17509" d="M229.319,243.863v-2.042c.667,0,1.336-.049,1.994.016a.962.962,0,0,1,.747,1.106,1.019,1.019,0,0,1-.982.916c-.573.016-1.148,0-1.758,0" transform="translate(-214.651 -226.337)" fill="gray" />
                    <path id="Path_17510" data-name="Path 17510" d="M229.353,186.627v-1.656c.476,0,.952-.032,1.421.01a.817.817,0,0,1,.662.826.808.808,0,0,1-.676.812c-.461.038-.927.009-1.407.009" transform="translate(-214.682 -173.128)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Instant buy/sell
                </p>
              </div> </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="All_forms_of_P2P_exchange_available" data-name="All forms of P2P exchange  available" xmlns="http://www.w3.org/2000/svg" width="32.75" height="28.798" viewBox="0 0 32.75 28.798">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5556" data-name="Rectangle 5556" width="32.75" height="28.798" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_25104" data-name="Group 25104" clip-path="url(#clip-path)">
                    <path id="Path_17511" data-name="Path 17511" d="M0,.715A1.184,1.184,0,0,1,1.288,0c.5.033,1,0,1.5.007A.962.962,0,0,1,3.836,1.04q0,10.483,0,20.966c0,.578-.265.869-.948,1.063v3.777c.089.026.179.048.267.077a.952.952,0,0,1-.237,1.864c-.66.016-1.321.011-1.981,0a1.061,1.061,0,0,1-.936-.7v-.512a1.136,1.136,0,0,1,.944-.719v-3.8c-.535-.125-.629-.2-.944-.723Z" transform="translate(0 0)" fill="gray" />
                    <path id="Path_17512" data-name="Path 17512" d="M90.355,93.9c.619,0,1.215.013,1.81-.009a.817.817,0,0,0,.473-.184,6.906,6.906,0,0,1,3.2-1.579A1.326,1.326,0,0,1,96,92.106c.042,0,.084,0,.128,0,.028.516.024,1.028.089,1.532a4.783,4.783,0,0,0,4.581,4.1c.671.013,1.343,0,2.014.005a.956.956,0,0,1,1.017.994c.005,2.164,0,4.327,0,6.491,0,.051-.006.1-.012.189h-.353c-2.057,0-4.114-.007-6.171,0a6.794,6.794,0,0,1-4.7-1.773.661.661,0,0,0-.395-.142c-.606-.015-1.213-.007-1.842-.007Z" transform="translate(-84.575 -86.213)" fill="gray" />
                    <path id="Path_17513" data-name="Path 17513" d="M454.826,23.206v3.786c.1.027.177.046.256.071a.954.954,0,0,1-.26,1.874c-.607.01-1.215,0-1.823,0a.962.962,0,0,1-.219-1.912c.031-.008.062-.013.11-.023v-3.79c-.75-.216-.943-.472-.943-1.253q0-10.313,0-20.625c0-.833.348-1.178,1.186-1.179.544,0,1.087,0,1.631,0a.959.959,0,0,1,1.019,1.024q0,10.489,0,20.977c0,.576-.287.889-.958,1.046" transform="translate(-423.038 -0.147)" fill="gray" />
                    <path id="Path_17514" data-name="Path 17514" d="M210.969,90.143h.386c2.057,0,4.114.006,6.171,0a6.764,6.764,0,0,1,4.669,1.763.662.662,0,0,0,.392.15c.615.016,1.23.007,1.861.007v9.594c-.536,0-1.076.017-1.613-.006a1.092,1.092,0,0,0-.824.305,6.483,6.483,0,0,1-3.367,1.52v-.367q0-3.038,0-6.075a2.887,2.887,0,0,0-3.042-3.054,16.446,16.446,0,0,1-2.232-.061,2.807,2.807,0,0,1-2.4-2.806c0-.308,0-.616,0-.968" transform="translate(-197.473 -84.375)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">High-end blockchain security
                </p>
              </div></div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="Spontaneous_buy_and_sell_of_bitcoins" data-name="Spontaneous buy and sell  of bitcoins" xmlns="http://www.w3.org/2000/svg" width="32.75" height="32.755" viewBox="0 0 32.75 32.755">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5557" data-name="Rectangle 5557" width="32.75" height="32.755" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_25105" data-name="Group 25105" clip-path="url(#clip-path)">
                    <path id="Path_17515" data-name="Path 17515" d="M15.479,180.116c-.621-.084-1.247-.138-1.862-.255a16.286,16.286,0,0,1-11.67-8.41,15.471,15.471,0,0,1-1.933-8.3,16.241,16.241,0,0,1,1.259-5.723c.509.334,1.016.658,1.508,1,.06.042.065.218.039.317-.232.895-.547,1.774-.706,2.681a13.392,13.392,0,0,0,1.273,8.541,14.363,14.363,0,0,0,10.077,7.9,13.744,13.744,0,0,0,9.852-1.487c.2-.112.405-.231.611-.348.226.26.428.52.66.75s.479.414.78.67c-.758.4-1.472.824-2.219,1.173a15.689,15.689,0,0,1-5.63,1.437,1.142,1.142,0,0,0-.184.043Z" transform="translate(0 -147.361)" fill="gray" />
                    <path id="Path_17516" data-name="Path 17516" d="M143.681,17.272c-.085.632-.156,1.266-.258,1.894a15.527,15.527,0,0,1-1.043,3.577c-.2-.174-.386-.343-.583-.493a5.827,5.827,0,0,0-.607-.413c-.417-.242-.419-.235-.263-.706A14.024,14.024,0,0,0,138.988,7.84a13.875,13.875,0,0,0-9.737-5.8A14.277,14.277,0,0,0,120.062,3.9c-.1.058-.2.112-.207.05l-1.343-1.4c.529-.282,1.213-.69,1.933-1.021A16.357,16.357,0,0,1,140.537,6.71a15.872,15.872,0,0,1,3.066,7.95c.025.231.053.462.079.693Z" transform="translate(-110.931 0)" fill="gray" />
                    <path id="Path_17517" data-name="Path 17517" d="M100.672,89.993a10.593,10.593,0,0,1,10.549,10.574,10.7,10.7,0,0,1-10.564,10.666,10.813,10.813,0,0,1-10.672-10.7,10.7,10.7,0,0,1,10.686-10.543m.952,5.759c0-.371.01-.691,0-1.01a.852.852,0,0,0-.559-.82.95.95,0,0,0-1.353.906c-.007.3,0,.608,0,.923-.977,0-1.9,0-2.831,0a.907.907,0,0,0-.918.561.954.954,0,0,0,.907,1.355c.3.007.608,0,.906,0v5.762c-.344,0-.673-.013-1,0a.907.907,0,0,0-.892.94.92.92,0,0,0,.9.968c.181.011.362,0,.543,0h2.387c0,.339-.007.645,0,.952a.957.957,0,1,0,1.914,0c.008-.306,0-.612,0-.95.36,0,.689.012,1.016,0a2.758,2.758,0,0,0,2.536-1.652,2.7,2.7,0,0,0-.318-2.948.266.266,0,0,1,0-.385,2.7,2.7,0,0,0,.305-2.975,2.761,2.761,0,0,0-2.551-1.628c-.317-.012-.635,0-.989,0" transform="translate(-84.229 -84.236)" fill="gray" />
                    <path id="Path_17518" data-name="Path 17518" d="M35.75,62.925A2.875,2.875,0,1,1,32.928,60a2.9,2.9,0,0,1,2.822,2.921" transform="translate(-28.08 -56.165)" fill="gray" />
                    <path id="Path_17519" data-name="Path 17519" d="M392.026,364.905a2.875,2.875,0,1,1,2.822,2.921,2.9,2.9,0,0,1-2.822-2.921" transform="translate(-366.949 -338.913)" fill="gray" />
                    <path id="Path_17520" data-name="Path 17520" d="M242.081,211.878V210a1.11,1.11,0,0,1,.181-.03c.884,0,1.768-.009,2.652,0a.947.947,0,0,1,.911,1.348.783.783,0,0,1-.721.55c-1,.022-2,.008-3.022.008" transform="translate(-226.596 -196.536)" fill="gray" />
                    <path id="Path_17521" data-name="Path 17521" d="M242.2,272.012v-1.9c1.022,0,2.031-.025,3.038.011a.87.87,0,0,1,.775.942.887.887,0,0,1-.853.94c-.977.023-1.956.007-2.96.007" transform="translate(-226.711 -252.827)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">P2P exchange
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Dex