import React from 'react'


const SecurityofOur = () => {

  return (
    <section className="security pt-100 mb-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of </span>
            Our Binance Clone Script</h3>
            <p className="pharagraph head">We enable the tightened security modules in our Binance exchange clone script to protect your crypto trading platform.</p>
          </div>
        </div>
        <div className="binanceflexbox mt-0 version2" >
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.511" height="30.455" viewBox="0 0 34.511 30.455">
                  <g id="Verify_communication_through_Email" data-name="Verify communication through Email" transform="translate(0.002 0.012)">
                    <path id="Path_5238" data-name="Path 5238" d="M34.693,186.161v17.523a.569.569,0,0,0-.04.094,2.7,2.7,0,0,1-2.864,2.251c-9.543-.02-19.087-.007-28.63-.013a3.3,3.3,0,0,1-.9-.094A2.677,2.677,0,0,1,.2,203.165V186.423c0-.094.007-.189.013-.324.148.088.263.155.371.229q7.259,4.65,14.5,9.3a3.715,3.715,0,0,0,4.32.02q7.5-4.689,15-9.348A2.4,2.4,0,0,1,34.693,186.161Z" transform="translate(-0.185 -175.593)" fill="gray" />
                    <path id="Path_5239" data-name="Path 5239" d="M34.487,91.887q-7.986,4.984-15.966,9.954a2.629,2.629,0,0,1-2.986-.027Q7.872,96.911.2,92a.4.4,0,0,1-.222-.4,11.644,11.644,0,0,1,.189-2.13,2.253,2.253,0,0,1,2.116-1.719c2.029-.088,4.057-.027,6.146-.027V88.1c0,1.53-.007,3.053,0,4.583a2.038,2.038,0,0,0,1.51,2.123,2.923,2.923,0,0,0,.768.088c1.126.007,2.244.007,3.37.007h.4V97.92c0,.256.007.5.283.634.256.115.452-.02.64-.175,1.368-1.092,2.736-2.19,4.111-3.269a.963.963,0,0,1,.573-.2c1.247-.02,2.494-.007,3.74-.007a2.1,2.1,0,0,0,2.3-2.3V87.7h.4c1.7,0,3.417.034,5.122-.007a2.649,2.649,0,0,1,2.8,2.157.349.349,0,0,0,.04.088Z" transform="translate(0.022 -83.813)" fill="gray" opacity="0.5" />
                    <path id="Path_5240" data-name="Path 5240" d="M145.159,43.477V40.99c0-.566-.128-.7-.687-.7h-4.044a1.165,1.165,0,0,1-1.328-1.321V31.521a1.178,1.178,0,0,1,1.334-1.321H153.6a1.178,1.178,0,0,1,1.321,1.334v7.447a1.155,1.155,0,0,1-1.3,1.307c-1.348,0-2.7.007-4.044-.007a1.088,1.088,0,0,0-.735.256q-1.688,1.365-3.39,2.716A3.325,3.325,0,0,1,145.159,43.477Z" transform="translate(-129.724 -30.2)" fill="gray" opacity="0.5" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Verify Communication
                  Through Email</p> </div>
            </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.507" height="34.375" viewBox="0 0 34.507 34.375">
                  <g id="Jail_login" data-name="Jail login" transform="translate(0.002 0)">
                    <path id="Path_5213" data-name="Path 5213" d="M0,3.587c.054-.182.094-.364.155-.546A3.03,3.03,0,0,1,3.046.959c2.17-.007,4.334,0,6.5,0h21.8A3.028,3.028,0,0,1,34.4,3.217a2.113,2.113,0,0,1,.094.492c.007,1.759.007,3.525.007,5.331H.013C0,7.226,0,5.407,0,3.587ZM26.352,7.024h2.891A1.018,1.018,0,1,0,29.257,5H23.434a1.017,1.017,0,1,0-.013,2.022Zm-20.219-1a1.011,1.011,0,1,0-1.011,1,1.026,1.026,0,0,0,1.011-1Zm3.019,1a1.012,1.012,0,1,0-.987-1.037,1.012,1.012,0,0,0,.987,1.037ZM14.221,6a1.011,1.011,0,1,0-.608.947A1.016,1.016,0,0,0,14.221,6Z" transform="translate(0 -0.956)" fill="gray" />
                    <path id="Path_5214" data-name="Path 5214" d="M0,151H14.652a3.329,3.329,0,0,0-2.123,1.651c-.31-.04-.607-.094-.91-.108a2.853,2.853,0,0,0-2.2.849q-1.466,1.436-2.9,2.9a3.018,3.018,0,0,0-.728,3.1,3,3,0,0,0-1.55,1.894,3.989,3.989,0,0,0-.121.9c-.013,1.247.013,2.494-.007,3.74A3.13,3.13,0,0,0,5.8,168.893a3.238,3.238,0,0,0,.168,2.332H3.154a3.025,3.025,0,0,1-3.087-2.473c-.013-.054-.04-.1-.054-.155Q-.007,159.792,0,151Z" transform="translate(0 -140.888)" fill="gray" />
                    <path id="Path_5215" data-name="Path 5215" d="M93.278,189c.4-.849.4-.849-.249-1.5l-.452-.452a.991.991,0,0,1-.02-1.4q1.436-1.466,2.9-2.9a.991.991,0,0,1,1.4.02c.31.29.613.593.9.9a.293.293,0,0,0,.4.081c.209-.108.431-.189.667-.29,0-.479-.007-.964,0-1.442a1.015,1.015,0,0,1,1.072-1.085q1.971-.01,3.943,0A1.008,1.008,0,0,1,104.89,182q.01.607,0,1.213a.3.3,0,0,0,.222.35c.681.27.681.276,1.2-.243.189-.189.377-.384.573-.573a.983.983,0,0,1,1.375-.02q1.486,1.446,2.932,2.932a.983.983,0,0,1-.02,1.375c-.29.31-.593.613-.9.9a.368.368,0,0,0-.094.431.772.772,0,0,0,1.011.654,4.247,4.247,0,0,1,.775-.007A1,1,0,0,1,113,190.028c.007,1.334.007,2.676,0,4.01a1.009,1.009,0,0,1-1.051,1.038c-.472.007-.944.007-1.415.007a.363.363,0,0,0-.067.02c-.4.849-.4.842.249,1.5l.452.452a.991.991,0,0,1,.02,1.4c-.971.984-1.941,1.954-2.925,2.911a.985.985,0,0,1-1.4-.027c-.31-.3-.613-.593-.9-.91a.286.286,0,0,0-.4-.074c-.209.108-.431.189-.667.29v1.409a1.019,1.019,0,0,1-1.105,1.112H99.943a1.024,1.024,0,0,1-1.119-1.1c0-.391-.007-.789,0-1.179a.3.3,0,0,0-.222-.35c-.681-.263-.674-.276-1.193.249-.182.182-.364.371-.553.546a.991.991,0,0,1-1.422.02q-1.456-1.425-2.885-2.885a.986.986,0,0,1,.027-1.422c.29-.3.58-.6.883-.876.148-.135.135-.249.088-.431a.772.772,0,0,0-1.011-.654,4.242,4.242,0,0,1-.775.007,1.007,1.007,0,0,1-1.031-1.018c-.007-1.334-.007-2.676,0-4.01a1,1,0,0,1,1.051-1.031c.472-.007.944-.007,1.415-.007A.336.336,0,0,1,93.278,189Zm8.586-2a5.051,5.051,0,1,0,5.055,5.048A5.051,5.051,0,0,0,101.864,187Z" transform="translate(-84.611 -168.796)" fill="#b0b0b0" />
                    <path id="Path_5216" data-name="Path 5216" d="M296.736,152.865A3.26,3.26,0,0,0,294.6,151.2h14.645v17a3.037,3.037,0,0,1-3.221,3.2h-2.73a3.2,3.2,0,0,0,.148-2.325,3.054,3.054,0,0,0,1.651-2.3,4.035,4.035,0,0,0,.034-.64c0-1.159-.027-2.312.007-3.471a3.162,3.162,0,0,0-1.651-3.066c.034-.357.094-.7.1-1.038a2.756,2.756,0,0,0-.782-1.988c-1.011-1.038-2.029-2.069-3.073-3.073a2.879,2.879,0,0,0-2.655-.714C296.972,152.811,296.865,152.838,296.736,152.865Z" transform="translate(-274.745 -141.074)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Jail Login</p>
              </div>
            </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.985" height="34.515" viewBox="0 0 31.985 34.515">
                  <g id="_2FA" data-name="2FA" transform="translate(0)">
                    <path id="Path_5209" data-name="Path 5209" d="M33.332,0c.559.088,1.132.148,1.685.263A13.762,13.762,0,0,1,45.9,11.552a8.973,8.973,0,0,1,.135,1.274c.02,1.112.007,2.224.007,3.377a10.665,10.665,0,0,0-7.535-.054V13.877a6.564,6.564,0,0,0-.66-2.925A6.1,6.1,0,0,0,26.511,12.1a7.374,7.374,0,0,0-.229,1.624c-.034,1.334-.013,2.676-.007,4.01,0,.067.013.128.02.209h2v-.4c.007-1.4-.034-2.79.04-4.179a4.063,4.063,0,0,1,8.1-.007c.067,1.206.034,2.426.034,3.639a.479.479,0,0,1-.168.3c-.674.607-1.348,1.213-2.035,1.806a.516.516,0,0,1-.377.115.93.93,0,0,1-.775-1.024c-.007-1.6,0-3.215,0-4.819v-.391H31.128a.579.579,0,0,0-.027.142c0,1.766-.02,3.525.013,5.291a2.841,2.841,0,0,0,1.624,2.534.609.609,0,0,1,.108.067c-.377,1.126-.741,2.224-1.126,3.37a3.91,3.91,0,0,1-2.4-1.348,4.006,4.006,0,0,1-1.011-2.655H26.3a4.977,4.977,0,0,0,.27,1.813,6.008,6.008,0,0,0,4.83,4.188c.256.047.3.148.337.377.135.829.31,1.651.479,2.473a4.109,4.109,0,0,0,.135.411,7.878,7.878,0,0,1-2.709-.438,8.687,8.687,0,0,1-5.553-5.553,8.516,8.516,0,0,1-.452-2.878c0-2.379-.007-4.765,0-7.144a8.759,8.759,0,1,1,17.514-.383c0,.067.006.128.012.209h2.022c-.034-.452-.04-.91-.1-1.355A10.63,10.63,0,0,0,39.33,5.237a10.382,10.382,0,0,0-5.291-2.406,10.787,10.787,0,0,0-12.151,8.236,12.645,12.645,0,0,0-.283,2.568c-.034,2.379-.02,4.765-.013,7.144a10.605,10.605,0,0,0,3.9,8.418,10.413,10.413,0,0,0,7.05,2.487,3.14,3.14,0,0,0,.438-.013.545.545,0,0,1,.553.263c.559.674,1.152,1.314,1.732,1.968a1.5,1.5,0,0,0,.222.189c-.013.034-.02.061-.034.094l-1.779.263c-.121.02-.236.047-.357.067H31.431a1.763,1.763,0,0,0-.256-.061,13.164,13.164,0,0,1-7.5-3.093A13.509,13.509,0,0,1,18.707,20.67c-.007-2.291-.013-4.583.007-6.874a15.109,15.109,0,0,1,.162-2.211,13.4,13.4,0,0,1,3.6-7.305A13.562,13.562,0,0,1,29.76.263C30.319.148,30.885.088,31.445,0Z" transform="translate(-18.7)" fill="gray" />
                    <path id="Path_5210" data-name="Path 5210" d="M249.319,276.373c-.505-.088-1.024-.128-1.523-.263a8.311,8.311,0,0,1-6.288-6.308,8.419,8.419,0,0,1,3.727-9.1,8.209,8.209,0,0,1,9.186.094,8.072,8.072,0,0,1,3.727,5.789,8.465,8.465,0,0,1-5,9.065,8.2,8.2,0,0,1-2.628.667,2.806,2.806,0,0,0-.33.054Zm3.72-10.554a3.615,3.615,0,0,0-.762-2.662,3.2,3.2,0,0,0-2.5-1.186,3.093,3.093,0,0,0-2.049.728,3.464,3.464,0,0,0-1.24,3.12c-.216.013-.391.027-.566.034v6.9h7.69v-6.908C253.437,265.846,253.275,265.833,253.039,265.819Z" transform="translate(-226.263 -241.867)" fill="#b0b0b0" />
                    <path id="Path_5211" data-name="Path 5211" d="M340.3,389.037V386.2h3.646v2.837Z" transform="translate(-318.625 -360.172)" fill="#b0b0b0" />
                    <path id="Path_5212" data-name="Path 5212" d="M351.182,330.168h-2.46a1.525,1.525,0,0,1,.5-1.577,1.187,1.187,0,0,1,1.361-.074C351.222,328.881,351.256,329.488,351.182,330.168Z" transform="translate(-326.454 -306.209)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Two-Factor
                  Authentication</p>
              </div></div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.646" height="32.486" viewBox="0 0 34.646 32.486">
                  <g id="Cloudflare_integration" data-name="Cloudflare integration" transform="translate(0 0.002)">
                    <path id="Path_5245" data-name="Path 5245" d="M-1,378.752a3.382,3.382,0,0,1,.964-1.779,3.052,3.052,0,0,1,4.92.971c.027.061.061.121.094.2h8.29v-2.015H15.3v-2.211a3.037,3.037,0,0,0,2.029-.013v2.2h2.035v2.029h8.317a3.055,3.055,0,0,1,2.359-1.961A3.119,3.119,0,0,1,33.6,378.6a3.072,3.072,0,0,1-5.81,1.826c-.067-.148-.121-.256-.33-.256-2.642.007-5.277.007-7.919.007-.04,0-.088.007-.155.013v1.988h-6.1V380.2H4.971a3.027,3.027,0,0,1-2.291,1.948,3.113,3.113,0,0,1-3.619-2.386A1.549,1.549,0,0,0-1,379.568C-1,379.292-1,379.022-1,378.752Z" transform="translate(1 -349.716)" fill="#b0b0b0" />
                    <path id="Path_5246" data-name="Path 5246" d="M70.5,93.757H63.428A3.051,3.051,0,0,1,60.2,90.536V76.592c0-.357.047-.714.067-1.092h26.3v4.037H83.559a5.075,5.075,0,0,0-.081,10.15c1.024.013,2.049,0,3.093,0V93.73H76.281a3.031,3.031,0,0,0-.782-3.195,2.939,2.939,0,0,0-2.312-.849A3.107,3.107,0,0,0,70.5,93.757Z" transform="translate(-56.075 -71.426)" fill="gray" />
                    <path id="Path_5247" data-name="Path 5247" d="M75.9,17.06A5.083,5.083,0,0,1,79.1,15.126a5.507,5.507,0,0,1,.944-.067q9.4-.01,18.81,0h.344v2.008C91.455,17.06,83.711,17.06,75.9,17.06Z" transform="translate(-70.717 -15.056)" fill="gray" />
                    <path id="Path_5248" data-name="Path 5248" d="M369.819,165.63v6.059a1.627,1.627,0,0,1-.168.02c-1.644,0-3.289.013-4.933-.007a3.035,3.035,0,0,1-1.227-5.8,3.247,3.247,0,0,1,1.206-.276c1.658-.034,3.309-.013,4.967-.013C369.7,165.617,369.745,165.623,369.819,165.63Zm-3.06,4.03v-1.988h-1.988v1.988Z" transform="translate(-337.274 -155.464)" fill="gray" />
                    <path id="Path_5249" data-name="Path 5249" d="M243.029,317.3a1.014,1.014,0,1,1-1.024-1A1.027,1.027,0,0,1,243.029,317.3Z" transform="translate(-224.69 -295.997)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Cloudflare Integration
                </p>
              </div> </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.512" height="32.345" viewBox="0 0 34.512 32.345">
                  <g id="SQL_injection_Prevention" data-name="SQL injection  Prevention" transform="translate(0 -0.005)">
                    <path id="Path_5234" data-name="Path 5234" d="M316.226,23.744c-.061.3-.115.613-.189.917a7.025,7.025,0,1,1,.129-2.487.933.933,0,0,0,.067.222C316.226,22.841,316.226,23.292,316.226,23.744Zm-8.094-.054c-.458-.472-.869-.917-1.3-1.334a1.006,1.006,0,1,0-1.429,1.415q.991,1.031,2.022,2.022a.969.969,0,0,0,1.422.007c1.361-1.341,2.7-2.7,4.05-4.044a.954.954,0,0,0,.263-.957,1,1,0,0,0-1.732-.411c-.876.856-1.732,1.725-2.6,2.6-.228.217-.433.437-.7.707Z" transform="translate(-281.721 -15.986)" fill="#b0b0b0" />
                    <path id="Path_5235" data-name="Path 5235" d="M18.443,136.083a9,9,0,0,0,5.2,7.2,8.9,8.9,0,0,0,8.849-.634c.007.115.013.2.013.283v14.254a3,3,0,0,1-2.285,3.033,3.366,3.366,0,0,1-.9.108q-13.051.01-26.109,0A3.043,3.043,0,0,1,.03,157.158q-.01-8.957,0-17.92a3.016,3.016,0,0,1,3.1-3.154q7.562-.02,15.13-.007C18.308,136.077,18.362,136.083,18.443,136.083Zm-6.477,12.974a4.043,4.043,0,0,0-5.392-6.012,3.911,3.911,0,0,0-1.462,2.642,4.067,4.067,0,0,0,1.166,3.377c-.081.067-.135.121-.2.168A4.643,4.643,0,0,0,4.191,152.3a21.918,21.918,0,0,0-.094,3.12.953.953,0,0,0,1.031.863q3.993.01,7.986,0a1.006,1.006,0,0,0,1.065-1.058c.007-.687.013-1.368-.007-2.056a4.936,4.936,0,0,0-1.57-3.565c-.2-.195-.42-.363-.636-.552Zm11.444,3.188h3.909a1.016,1.016,0,1,0,.007-2.015H19.474a1.035,1.035,0,0,0-1.045.647,1.013,1.013,0,0,0,1,1.375C20.754,152.252,22.082,152.245,23.41,152.245Zm0,4.044h3.943a1.013,1.013,0,1,0,.067-2.022c-1.227-.013-2.446-.007-3.673-.007h-4.28a1.014,1.014,0,1,0-.034,2.022C20.761,156.3,22.082,156.289,23.41,156.289Zm-3.033-9.1a1.008,1.008,0,1,0-1,1.011,1.008,1.008,0,0,0,1-1.011Zm3.026-1a1.008,1.008,0,1,0,1.018,1,1.008,1.008,0,0,0-1.018-1Zm4.044,0a1.008,1.008,0,1,0,1.018,1,1.008,1.008,0,0,0-1.018-1Z" transform="translate(-0.025 -127.982)" fill="gray" />
                    <path id="Path_5236" data-name="Path 5236" d="M90.051,350.191a4.486,4.486,0,0,1,.438-2.568,3.088,3.088,0,0,1,3.222-1.382,3.015,3.015,0,0,1,2.339,2.487c.054.479.054.957.081,1.462Z" transform="translate(-83.974 -323.926)" fill="gray" />
                    <path id="Path_5237" data-name="Path 5237" d="M109.137,258.122a2.019,2.019,0,1,1-2.015-2.022A2.04,2.04,0,0,1,109.137,258.122Z" transform="translate(-98.018 -239.917)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">SQL Injection
                  Prevention</p> </div>
            </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="End_to_End_Encryption" data-name="End to End Encryption" xmlns="http://www.w3.org/2000/svg" width="34.506" height="34.514" viewBox="0 0 34.506 34.514">
                  <path id="Path_5223" data-name="Path 5223" d="M155,1a18.4,18.4,0,0,1,1.82.452,7.312,7.312,0,0,1,4.906,6.827c.04,2.473.013,4.94.013,7.413v1.227a9.268,9.268,0,0,0-6.066-3.687v-.364c0-1.469.007-2.945-.007-4.414A1.341,1.341,0,0,0,153.9,7.14a1.358,1.358,0,0,0-.917,1.348c-.007,1.449,0,2.9,0,4.347v.391a9.229,9.229,0,0,0-6.032,3.66c-.013-.128-.027-.209-.034-.283,0-2.709-.013-5.412,0-8.121a7.4,7.4,0,0,1,5.883-7.32c.283-.067.573-.108.856-.162Z" transform="translate(-137.077 -1)" fill="gray" />
                  <path id="Path_5224" data-name="Path 5224" d="M392.6,153.826a3.369,3.369,0,0,1-.849,1.8,7.471,7.471,0,0,1-1.247.89.429.429,0,0,0-.27.425c.013,1.348,0,2.7.007,4.044a.353.353,0,0,1-.2.377q-2.992,1.527-5.985,3.08a1.5,1.5,0,0,1-.155.061,1.7,1.7,0,0,1,.04-.236c.148-.674.29-1.355.452-2.029a.6.6,0,0,1,.27-.364c1.092-.58,2.184-1.146,3.289-1.7a.405.405,0,0,0,.27-.431q-.02-1.415,0-2.831a.345.345,0,0,0-.249-.371,3.352,3.352,0,1,1,4.549-3.761,1.921,1.921,0,0,0,.067.182C392.6,153.24,392.6,153.536,392.6,153.826Z" transform="translate(-358.094 -140.01)" fill="gray" />
                  <path id="Path_5225" data-name="Path 5225" d="M291.394,419.778a12.887,12.887,0,0,1-1.51-.62A2.774,2.774,0,0,1,288.7,417.7a.363.363,0,0,0-.418-.276q-1.9.02-3.8,0a.811.811,0,0,1-.5-.2c-.627-.559-1.233-1.139-1.88-1.746.108-.034.168-.061.243-.081.566-.182,1.132-.35,1.692-.553.182-.067.317-.088.438.081a1.3,1.3,0,0,0,1.233.492c.863-.04,1.725-.02,2.6-.007a.356.356,0,0,0,.4-.256,3.357,3.357,0,1,1,3.76,4.565,1.913,1.913,0,0,0-.182.067C291.98,419.778,291.684,419.778,291.394,419.778Z" transform="translate(-263.155 -385.272)" fill="gray" />
                  <path id="Path_5226" data-name="Path 5226" d="M1,317.128a4.789,4.789,0,0,1,.809-1.7,3.359,3.359,0,0,1,5.628.829.465.465,0,0,0,.539.35c.472-.027.944-.007,1.4-.007.263.674.512,1.328.782,2.022-.741,0-1.476.013-2.211-.007a.426.426,0,0,0-.492.317,3.348,3.348,0,0,1-3.262,2.042,3.39,3.39,0,0,1-3-2.325l-.182-.566C1,317.755,1,317.445,1,317.128Z" transform="translate(-1 -293.135)" fill="gray" />
                  <path id="Path_5227" data-name="Path 5227" d="M166.153,218.082A7.077,7.077,0,1,1,159.1,211,7.077,7.077,0,0,1,166.153,218.082Zm-6.066,4.36c0-.849.013-1.658-.007-2.46a.5.5,0,0,1,.337-.553,2.994,2.994,0,0,0,1.483-3.788,3.023,3.023,0,1,0-4.057,3.838.352.352,0,0,1,.229.384c-.007.755-.007,1.5,0,2.258,0,.108.007.216.013.324C158.76,222.442,159.407,222.442,160.087,222.442Z" transform="translate(-141.823 -196.846)" fill="#b0b0b0" />
                  <path id="Path_5228" data-name="Path 5228" d="M23.657,169.794l-.236-.216-1.88-1.88a.312.312,0,0,0-.4-.094,3.36,3.36,0,1,1,1.725-1.752.332.332,0,0,0,.1.431c.425.411.849.829,1.26,1.267a.432.432,0,0,1,.067.364C24.1,168.527,23.886,169.14,23.657,169.794Z" transform="translate(-15.388 -150.377)" fill="gray" />
                  <path id="Path_5229" data-name="Path 5229" d="M244.114,282.087a1.008,1.008,0,1,1-1.024-.991A1.008,1.008,0,0,1,244.114,282.087Z" transform="translate(-225.85 -262.218)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">End-To-End Encryption
                  Based SSL</p>
              </div> </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="28.029" height="34.508" viewBox="0 0 28.029 34.508">
                  <g id="Anti_Denial_of_Service_DoS_" data-name="Anti Denial of  Service(DoS)" transform="translate(0.001 0.001)">
                    <path id="Path_5230" data-name="Path 5230" d="M51.919,34.506a4.953,4.953,0,0,1-1.948-.775,4.216,4.216,0,0,1-1.813-3.545c-.007-3.3,0-6.6,0-9.907V4.347A4.25,4.25,0,0,1,51.508.1a4.7,4.7,0,0,1,.971-.1H65.344a4.227,4.227,0,0,1,4.32,4.259c.02,1.368.007,2.743.007,4.111,0,.074-.007.155-.013.27-.148.007-.29.02-.431.02H55.855a3.137,3.137,0,0,0-3.2,2.231,3.942,3.942,0,0,0-.148,1.126Q52.5,19.407,52.5,26.8a3.189,3.189,0,0,0,3.39,3.37H69.72a9.224,9.224,0,0,1-.236,1.307,4.176,4.176,0,0,1-3.356,2.959,1.729,1.729,0,0,0-.189.061Q58.932,34.51,51.919,34.506Zm7-28.158c.371,0,.741.013,1.112,0A.974.974,0,0,0,61,5.392a.942.942,0,0,0-.863-1.024,21.278,21.278,0,0,0-2.453,0,.923.923,0,0,0-.849,1.058.966.966,0,0,0,.97.923c.369.006.739,0,1.11,0Z" transform="translate(-48.156)" fill="gray" />
                    <path id="Path_5231" data-name="Path 5231" d="M164.127,223.579c-.714,0-1.422-.007-2.123,0a26.849,26.849,0,0,0-2.756.067,3.151,3.151,0,0,0,.31,6.261c1.388.04,2.783.013,4.179.02h.418c0,.829.034,1.611-.013,2.393a1.1,1.1,0,0,1-1.058,1,3.4,3.4,0,0,1-.371.007h-18.77c-1.031,0-1.442-.411-1.442-1.449V220a14.076,14.076,0,0,0,3.714.189q8.269.02,16.546.007c1,0,1.409.411,1.409,1.415,0,.62,0,1.233-.007,1.853A.678.678,0,0,0,164.127,223.579Z" transform="translate(-136.142 -205.173)" fill="#b0b0b0" />
                    <path id="Path_5232" data-name="Path 5232" d="M164.408,160.926c-.6-.04-1.159-.108-1.712-.108q-9.25-.01-18.493-.007a2.641,2.641,0,0,1-.566-.047,1.144,1.144,0,0,1,.115-2.244,3.13,3.13,0,0,1,.438-.02h18.8c1.018,0,1.422.411,1.422,1.436Z" transform="translate(-136.389 -147.818)" fill="#b0b0b0" />
                    <path id="Path_5233" data-name="Path 5233" d="M386.94,302.885v2.264c-.108.007-.189.02-.276.02-1.334,0-2.669.007-4,0a1.161,1.161,0,0,1-1.26-1.152,1.177,1.177,0,0,1,1.26-1.159c1.334-.007,2.669,0,4,0a2.742,2.742,0,0,0,.276.027Z" transform="translate(-358.941 -282.444)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Anti Denial Of
                  Service(Dos)</p>
              </div></div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.505" height="33.15" viewBox="0 0 34.505 33.15">
                  <g id="CSRF" transform="translate(0 0.001)">
                    <path id="Path_5217" data-name="Path 5217" d="M0,119.619V104.05c5.156,0,10.312-.007,15.467.013a1.506,1.506,0,0,0,1.436-.782c.634-1.018,1.328-2,1.981-3.006A.536.536,0,0,1,19.4,100q7.36.01,14.726.007H34.5V119.12a3.026,3.026,0,0,1-3.12,3.127c-1.5.007-3.013,0-4.516,0H24.546c.047-.142.088-.256.115-.371a20.569,20.569,0,0,0,.593-2.891,47.092,47.092,0,0,0,.061-4.778,2.771,2.771,0,0,0-1.779-2.568,15.526,15.526,0,0,0-4.576-1.179,16.391,16.391,0,0,0-7.757,1.078,3.034,3.034,0,0,0-2.035,2.918c-.007,1.146-.054,2.291.013,3.437.061,1.011.229,2.022.384,3.033a11.627,11.627,0,0,0,.377,1.328H3.208c-.135,0-.27,0-.4-.007a3.044,3.044,0,0,1-2.682-2.17C.081,119.922.04,119.774,0,119.619Z" transform="translate(0 -93.945)" fill="gray" />
                    <path id="Path_5218" data-name="Path 5218" d="M0,12.78c.054-.182.094-.364.155-.546a3.022,3.022,0,0,1,2.891-2.076c2.291-.007,4.583,0,6.874,0H31.353a3,3,0,0,1,3.12,2.628,9.849,9.849,0,0,1,.013,1.415H34.1c-5.109,0-10.224.007-15.333-.007a1.292,1.292,0,0,0-1.2.64c-.654,1.018-1.348,2.015-2.008,3.033a.727.727,0,0,1-.694.384C9.911,18.238,4.958,18.244,0,18.244Q0,15.506,0,12.78Zm5.061,2.426a1.011,1.011,0,1,0-1.008-1.015,1.011,1.011,0,0,0,1.008,1.015Zm5.048-1a1.011,1.011,0,1,0-1.018.991,1.011,1.011,0,0,0,1.018-.991Zm3.013-1.018a1.011,1.011,0,1,0,.947.608A1.016,1.016,0,0,0,13.122,13.188Z" transform="translate(0 -10.156)" fill="gray" />
                    <path id="Path_5219" data-name="Path 5219" d="M177.949,288.281a12.527,12.527,0,0,1-.4,3.922,10.238,10.238,0,0,1-5.014,6.194,1.211,1.211,0,0,1-1.3,0,10.1,10.1,0,0,1-5.412-8.91c-.04-1.166-.007-2.339-.007-3.5a1.034,1.034,0,0,1,.728-1.058,14.189,14.189,0,0,1,10.608-.02,1.045,1.045,0,0,1,.8,1.139Zm-6.807,3.067c-.256-.283-.465-.553-.708-.782a1.009,1.009,0,0,0-1.4,0,.991.991,0,0,0-.013,1.422c.472.492.957.977,1.456,1.449a.949.949,0,0,0,1.341.04q1.516-1.466,2.979-2.979a.906.906,0,0,0,.162-1.038.957.957,0,0,0-.856-.613,1.06,1.06,0,0,0-.869.371C172.557,289.919,171.877,290.6,171.142,291.348Z" transform="translate(-154.63 -265.438)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Cross-Site Request
                  Forgery Protection</p>
              </div> </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg id="SSRF" xmlns="http://www.w3.org/2000/svg" width="34.496" height="34.505" viewBox="0 0 34.496 34.505">
                  <path id="Path_5241" data-name="Path 5241" d="M0,3.8c.054-.209.1-.411.155-.613A4.3,4.3,0,0,1,4.259.03q11.555-.01,23.11,0a4.274,4.274,0,0,1,4.246,4.32,4.286,4.286,0,0,1-4.246,4.293q-11.555.02-23.11,0a4.3,4.3,0,0,1-4.2-3.585A1.088,1.088,0,0,0,0,4.883Zm4.3-.89A1.429,1.429,0,1,0,5.742,4.33,1.409,1.409,0,0,0,4.3,2.915Zm5.776,2.858A1.429,1.429,0,1,0,8.627,4.357a1.429,1.429,0,0,0,1.449,1.411Z" transform="translate(0 -0.025)" fill="gray" />
                  <path id="Path_5242" data-name="Path 5242" d="M0,174.433a4.864,4.864,0,0,1,.876-2.089,4.22,4.22,0,0,1,3.262-1.7c.526-.02,1.058-.007,1.584-.007H27.181a4.281,4.281,0,0,1,4.313,3.269,4.133,4.133,0,0,1,0,2.029c-.108-.054-.2-.094-.283-.142a6.439,6.439,0,0,0-9.159,3.168.413.413,0,0,1-.465.3c-5.668-.007-11.343-.034-17.011.007A4.373,4.373,0,0,1,.047,175.6.5.5,0,0,0,0,175.519Zm10.062,1.954a1.439,1.439,0,1,0-1.436-1.436,1.439,1.439,0,0,0,1.436,1.436Zm-4.32-1.422A1.429,1.429,0,1,0,4.3,176.388a1.429,1.429,0,0,0,1.442-1.423Z" transform="translate(0 -159.136)" fill="gray" />
                  <path id="Path_5243" data-name="Path 5243" d="M321.88,293.262a8.087,8.087,0,0,1-1.281-.634,2.211,2.211,0,0,1-.876-1.82c-.013-1.719-.02-3.437,0-5.149a2.481,2.481,0,0,1,2.42-2.446c.135-.007.263,0,.452,0V282.8a17.363,17.363,0,0,1,.088-2.446,3.572,3.572,0,0,1,7.077.559c.027.748.007,1.5.007,2.3.189,0,.344-.007.492,0a2.478,2.478,0,0,1,2.386,2.413q.03,2.608,0,5.216a2.5,2.5,0,0,1-1.894,2.345c-.088.02-.168.054-.256.074Zm5.742-10.082c0-.755.02-1.5-.007-2.231a1.4,1.4,0,0,0-1.307-1.341,1.366,1.366,0,0,0-1.489,1.092c-.081.815-.02,1.644-.02,2.487.936-.008,1.866-.008,2.823-.008Z" transform="translate(-298.164 -258.757)" fill="#b0b0b0" />
                  <path id="Path_5244" data-name="Path 5244" d="M0,344.953a9.132,9.132,0,0,1,.526-1.476,4.088,4.088,0,0,1,3.747-2.271c5.3-.027,10.6-.007,15.9-.007.04,0,.088.007.175.013a2.443,2.443,0,0,1-.168.216,5.4,5.4,0,0,0-1.5,3.821c-.007,1.5,0,3.006,0,4.569H4.475a4.283,4.283,0,0,1-4.421-3.633A1.049,1.049,0,0,0,0,346.032C0,345.674,0,345.31,0,344.953Zm10.062-.876a1.439,1.439,0,1,0,1.332.887,1.441,1.441,0,0,0-1.332-.887ZM5.742,345.5a1.431,1.431,0,1,0-.407,1.018,1.429,1.429,0,0,0,.407-1.018Z" transform="translate(0 -318.199)" fill="gray" />
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Server-Side Request
                  Forgery Protection</p>
              </div> </div>
          </div>
          <div className="binanceboxt card" >
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.506" height="34.517" viewBox="0 0 34.506 34.517">
                  <g id="DDoS" transform="translate(0 0.003)">
                    <path id="Path_5220" data-name="Path 5220" d="M-.1,245.106a5.859,5.859,0,0,1,.762-.768c1.887-1.281,3.788-2.534,5.681-3.8a1.022,1.022,0,0,1,1.644.883c-.007.29,0,.58,0,.9h6.126V240.2h6.059v2.123h6.146v-.944a.994.994,0,0,1,.546-.937.962.962,0,0,1,1.051.067c2,1.334,4,2.655,5.991,4a1,1,0,0,1,0,1.732q-2.992,2.012-5.991,4a.969.969,0,0,1-1.078.054.99.99,0,0,1-.519-.917v-.95H20.213v2.008h.93a1,1,0,0,1,.944.539.982.982,0,0,1-.074,1.078c-1.328,1.988-2.649,3.983-3.983,5.958a2.971,2.971,0,0,1-.6.512h-.54a2.925,2.925,0,0,1-.58-.479c-1.348-1.995-2.682-4.01-4.017-6.018a.961.961,0,0,1-.061-1.051.989.989,0,0,1,.937-.539h.937v-1.995H7.994v.9a1.021,1.021,0,0,1-1.644.877c-1.894-1.267-3.794-2.521-5.675-3.8a5.133,5.133,0,0,1-.762-.768C-.1,245.47-.1,245.288-.1,245.106Z" transform="translate(0.1 -224.009)" fill="gray" />
                    <path id="Path_5221" data-name="Path 5221" d="M154.967-.024V3.251c0,.586-.007,1.166,0,1.752a.992.992,0,0,0,.984,1.031c1.368.013,2.743.013,4.111,0a.991.991,0,0,0,.97-1.078c.007-1.53,0-3.053,0-4.583v-.4h2.878a1.036,1.036,0,0,1,1.166,1.159v10.84c0,.357.007.721,0,1.078a1.008,1.008,0,0,1-1.051,1.065q-6.025.01-12.057,0a1,1,0,0,1-1.038-1.045q-.01-6.025,0-12.057a.981.981,0,0,1,.937-1.038C152.885-.045,153.909-.024,154.967-.024Z" transform="translate(-140.747 0.033)" fill="#b0b0b0" />
                    <path id="Path_5222" data-name="Path 5222" d="M241.3.3h1.981v4H241.3Z" transform="translate(-225.031 -0.278)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <p className="card-title">Anti Distributed
                  Denial Of Service</p>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur