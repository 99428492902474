import React from 'react'
import Slider from "react-slick";
import { StaticImage } from 'gatsby-plugin-image';
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Banner extends React.Component {
  

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Binance</span> Clone Script</h1>
                <p className="pharagraph">Launch your crypto exchange like Binance with our ready-made Binance clone script integrated with advanced trading modules and security protocols. Our customized Binance clone software is specially designed for startups to create a crypto exchange like Binance rapidly. Let’s turn your vision of launching a crypto exchange like Binance into a reality with our industry's best solution.</p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
              <div className="screen">
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png"
                  alt="Binance clone script dashboard overview"
                  width={876}
                  height={501}
                />
                <div className="outder">
                  <Slider {...settings}>
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/binance-clone-script.png"
                      alt="Binance clone script slide1"
                      width={647}
                    />
                    <StaticImage
                    src="https://coinsclone.mo.cloudinary.net/images/binance-new/binance-clone.png"
                    alt="Binance clone script slide2"
                    width={647}
                  />
                    <StaticImage
                    src="https://coinsclone.mo.cloudinary.net/images/binance-new/binance-clone-software.png"
                    alt="Binance clone script slide3"
                    width={647}
                  />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner