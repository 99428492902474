import { StaticImage } from "gatsby-plugin-image";
import React, { Component } from "react";


class CoreFeatures extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  };

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2">
              <span className="heading-h3">
                <span className="bluecolor">Core Features</span> of{" "}
              </span>
              Our Binance Clone Script
            </h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul
                className="nav nav-tabs tabNav nav-pills"
                id="myTab"
                role="presentation"
              >
                <li
                  className="current"
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab1")}
                >
                  User Dashboard
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab2")}
                >
                  Admin Dashboard
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab3")}
                >
                  Order Book
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab4")}
                >
                  Order Types
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab5")}
                >
                  Extended Trade View
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab6")}
                >
                  Live Price Ticker
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab7")}
                >
                  KYC/AML
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab8")}
                >
                  Support System
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab9")}
                >
                  Multi-lingual
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab10")}
                >
                  API Documentation
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab11")}
                >
                  Referral program
                </li>
                <li
                  role="presentation"
                  onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, "tab12")}
                >
                  Crypto/Fiat Payment Gateway integration
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Dashboard</h4>
                        <p className="pharagraph">
                        The user dashboard comes with a strong user login feature that enables your users to sign in with their credentials every time. Our stunning user dashboard lets your users trade cryptocurrencies instantly through an order book system, monitor their crypto wallet, analyze their performance, and much more.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/user-dashboard.png"
                      alt="User Dashboard"
                      width={424}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Dashboard</h4>
                        <p className="pharagraph">
                        In this panel, the admin of the platform can control all the functions and security modules. As the owner, you can monitor all the user transactions, crypto wallet funds, withdrawal history, deposit history, pending transactions, and more. Our admin panel comes with a smooth and user-friendly interface.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/admin-dashboard.png"
                      alt="Admin Dashboard"
                      width={424}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Order Book</h4>
                        <p className="pharagraph">
                        The order book is empowered with the latest and most transparent live order book system. It provides the list of all open orders that are available on your exchange for a particular trading pair.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/order-book.png"
                      alt="Order Book"
                      width={424}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Order Types</h4>
                        <p className="pharagraph">
                        We enable Limit Orders, Market Orders, and Stop Orders in our binance exchange clone script which helps your users to buy or sell cryptocurrencies instantly as per their preference based on the price movement.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/order-types.png"
                      alt="Order Types"
                      width={390}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Extended Trade View</h4>
                        <p className="pharagraph">
                        We provide trading view charts for your users to obtain the correct status of the market trend and price movements precisely. A lot of tools are integrated along with the chart for easy and efficient trading.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/extended-trade-view.png"
                      alt="Extended Trade View"
                      width={390}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Live Price Ticker</h4>
                        <p className="pharagraph">
                        Updated live prices of each trading pair will be displayed for users to check and trade accordingly.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/live-price-ticker.png"
                      alt="Live Price Ticker"
                      width={478}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC/AML</h4>
                        <p className="pharagraph">
                        Your users will submit their ID proofs and legal documents for verification before they can start trading. You can analyze those documents and give permission for exchanging cryptos.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/KYC-AML.png"
                      alt="KYC/AML"
                      width={478}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support System</h4>
                        <p className="pharagraph">
                        Instant support system that helps your users to raise queries and also a chat box for them to directly chat with the admin.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/support-system.png"
                      alt="Support System"
                      width={478}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-lingual</h4>
                        <p className="pharagraph">
                        We provide multiple languages support to make your exchange platform compete and engage with global crypto users & investors.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/multi-lingual.png"
                      alt="Multi-lingual"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">API Documentation</h4>
                        <p className="pharagraph">
                        We provide clear API documentation which enables you to obtain real-time market data and make trades easily.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/api-documentation.png"
                      alt="Multi-lingual"
                      width={460}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Referral program</h4>
                        <p className="pharagraph">
                        We have integrated this core feature where your users can refer to another person and earn rewards instantly. This feature helps you increase the user count quickly and attract many users easily.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/referral-program.png"
                      alt="Referral program"
                      width={460}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">
                          Crypto/Fiat Payment Gateway integration
                        </h4>
                        <p className="pharagraph">
                        We integrate multiple payment methods which help your users to buy or sell cryptocurrencies in their desired method easily.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance-new/crypto-fiat-payment-gateway-integration.png"
                      alt="Referral program"
                      width={240}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures;
