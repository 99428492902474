import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <section className="banner casestudy pt-0">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/binance-new/altcoin-logo.webp"
                    alt="Altcoin Traders Logo"
                    width={316}
                    className='mb-4'
                />
                <ul>
                    <li>AltCoinTrader, a Startup from South Africa entered the Crypto market, looking for a cost-effective solution.</li>
                    <li>Using our Binance exchange clone software, they turned into a <b>Million-Dollar Enterprise</b> from being a modest company.</li>
                    <li>The Startup met high demands while also producing significant brand recognition, user growth, and trading volume.</li>
                    <li>Substantial returns, Rapid growth, and Market leadership due to strategic investments from AltCoinTrader.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/binance-new/altcoin.webp"
                alt="Altcoin Traders"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo of our Binance clone script </a>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default CaseStudy